import { MenuItem } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Link } from 'gatsby'
import React, { FC } from 'react'

const useStyles = makeStyles((theme) =>
  createStyles({
    link: {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
    menuItem: {
      textColor: theme.palette.secondary,
    },
  }),
)

interface DropdownButtonItemProps {
  title: string
  to: string
}

const DropdownButtonItem: FC<DropdownButtonItemProps> = ({ title, to }) => {
  const classes = useStyles()

  return (
    <Link className={classes.link} to={to}>
      <MenuItem className={classes.menuItem}>{title}</MenuItem>
    </Link>
  )
}

export default DropdownButtonItem

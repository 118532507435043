import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { FC, useRef, useState } from 'react'

import LinkButton from './LinkButton'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
    },
  }),
)

interface DropdownButtonProps {
  title: string
  to: string
}

const DropdownButton: FC<DropdownButtonProps> = ({ title, to, children }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child as React.ReactElement, {
      buttonProps: { onClick: handleClose },
    }),
  )

  return (
    <div
      className={classes.root}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      role="button"
      tabIndex={0}
    >
      <LinkButton
        buttonProps={{ endIcon: <ExpandMoreIcon /> }}
        ref={anchorRef}
        title={title}
        to={to}
      />
      <Popper
        anchorEl={anchorRef.current}
        disablePortal
        open={open}
        role={undefined}
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center top',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  {childrenWithProps}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default DropdownButton

import { Box } from '@material-ui/core'
import React, { FC } from 'react'

import Phone from './Phone'

const TopHeaderMobile: FC = () => {
  return (
    <Box display="flex" justifyContent="center">
      <Phone />
    </Box>
  )
}

export default TopHeaderMobile

import { Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Variant } from '@material-ui/core/styles/createTypography'
import React, { FC } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(4),
    },
    uppercase: {
      textTransform: 'uppercase',
    },
  }),
)

interface SectionHeaderProps {
  mainTitle: string
  subTitle?: string
}

interface TitleTextProps {
  text: string
  component: Variant
  uppercase?: boolean
}

const TitleText: FC<TitleTextProps> = ({
  text,
  component,
  uppercase = false,
}) => {
  const classes = useStyles()

  return (
    <Typography
      align="center"
      className={uppercase ? classes.uppercase : undefined}
      color="secondary"
      variant={component}
    >
      {text}
    </Typography>
  )
}

const SectionHeader: FC<SectionHeaderProps> = ({ mainTitle, subTitle }) => {
  const classes = useStyles()

  return (
    <Container className={classes.root} disableGutters>
      <TitleText component="h2" text={mainTitle} uppercase />
      {subTitle && <TitleText component="h3" text={subTitle} />}
    </Container>
  )
}

export default SectionHeader

import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { FC } from 'react'

import EmailLink from '../shared/EmailLink'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      paddingRight: theme.spacing(1),
    },
    root: {
      color: theme.palette.primary.contrastText,
    },
  }),
)

const Email: FC = () => {
  const classes = useStyles()

  return (
    <Box alignItems="center" className={classes.root} display="flex">
      <FontAwesomeIcon className={classes.icon} icon={faEnvelope} size="lg" />
      <EmailLink color="inherit" variant="overline" />
    </Box>
  )
}

export default Email

import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import React, { FC } from 'react'

import Footer from '../footer/Footer'
import Header from '../header/Header'
import ScrollTopButton from '../ScrollTopButton'
import TopHeader from '../top-header/TopHeader'

interface PageLayoutProps {
  pathname: string
}

const PageLayout: FC<PageLayoutProps> = ({ children, pathname }) => (
  <>
    <CssBaseline />
    <Container disableGutters maxWidth={false}>
      <TopHeader />
      <Header />
      {children}
      <ScrollTopButton to={`${pathname}#strona-glowna`} />
      <Footer />
    </Container>
  </>
)

export default PageLayout

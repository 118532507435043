import { Container, Hidden } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { FC } from 'react'

import TopHeaderDesktop from './TopHeaderDesktop'
import TopHeaderMobile from './TopHeaderMobile'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,

      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(1),
    },
  }),
)

const TopHeader: FC = () => {
  const classes = useStyles()

  return (
    <Container
      className={classes.root}
      disableGutters
      id="strona-glowna"
      maxWidth={false}
    >
      <Hidden smDown>
        <TopHeaderDesktop />
      </Hidden>
      <Hidden mdUp>
        <TopHeaderMobile />
      </Hidden>
    </Container>
  )
}

export default TopHeader

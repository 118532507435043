import { Box, Link } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'

interface AddressLinkProps {
  center?: boolean
}

const AddressLink: FC<AddressLinkProps> = ({ center = false }) => {
  const {
    site: {
      siteMetadata: {
        contact: {
          address: { street, description, zipCode },
          googleMapsUrl,
        },
      },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          contact {
            address {
              street
              description
              zipCode
            }
            googleMapsUrl
          }
        }
      }
    }
  `)

  return (
    <Link
      color="textSecondary"
      href={googleMapsUrl}
      rel="noopener"
      style={{ whiteSpace: 'pre' }}
      target="_blank"
    >
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent={center ? 'center' : 'flex-start'}
      >
        <span>{street}</span>
        <span>{description}</span>
      </Box>
      <span>{zipCode}</span>
    </Link>
  )
}

export default AddressLink

import { Link, LinkProps } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'

type EmailLinkProps = LinkProps

const EmailLink: FC<EmailLinkProps> = (props) => {
  const {
    site: {
      siteMetadata: {
        contact: { email },
      },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          contact {
            email
          }
        }
      }
    }
  `)

  return (
    <Link color="textSecondary" href={`mailto:${email}`} {...props}>
      {email}
    </Link>
  )
}

export default EmailLink

import { Grid } from '@material-ui/core'
import React, { FC } from 'react'

import Email from './Email'
import Phone from './Phone'
import Skype from './Skype'

const Contact: FC = () => {
  return (
    <Grid container spacing={4}>
      <Grid item>
        <Phone />
      </Grid>
      <Grid item>
        <Email />
      </Grid>
      <Grid item>
        <Skype />
      </Grid>
    </Grid>
  )
}

export default Contact

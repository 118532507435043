import { Container, Grid } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { FC } from 'react'

import SocialMedia from '../shared/SocialMedia'
import Address from './Address'
import Contact from './Contact'
import FooterInfoItem from './FooterInfoItem'

const CONTENT = [
  {
    content: <Contact />,
    title: 'Kontakt',
  },
  {
    content: <Address />,
    title: 'Adres',
  },
  {
    content: <SocialMedia />,
    title: 'Media Społecznościowe',
  },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey[200],
      padding: theme.spacing(4),
    },
  }),
)

const FooterInfo: FC = () => {
  const classes = useStyles()

  return (
    <Container className={classes.root} disableGutters maxWidth={false}>
      <Container disableGutters maxWidth="lg">
        <Grid container spacing={2}>
          {CONTENT.map((c) => {
            return (
              <Grid item key={c.title} md={4} xs={12}>
                <FooterInfoItem {...c} />
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </Container>
  )
}

export default FooterInfo

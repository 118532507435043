import { Hidden } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'

import DesktopAppBar from './desktop/DesktopAppBar'
import MobileAppBar from './mobile/MobileAppBar'

const Header: FC = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { template: { eq: "services" } } }
        sort: { fields: frontmatter___order }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              order
            }
          }
        }
      }
    }
  `)

  const services = data.allMarkdownRemark.edges.map((service) => {
    const title = service.node.frontmatter.title
    const { slug } = service.node.fields

    return {
      link: slug,
      title,
    }
  })

  const MENU = [
    {
      link: '/#strona-glowna',
      title: 'Strona Główna',
    },
    {
      link: '/#o-mnie',
      title: 'O Mnie',
    },
    {
      link: '/#oferta',
      submenu: services,
      title: 'Oferta',
    },
    {
      link: '/#cennik',
      title: 'Cennik',
    },
    {
      link: '/#blog',
      title: 'Blog',
    },
    {
      link: '/#kontakt',
      title: 'Kontakt',
    },
  ]

  return (
    <>
      <Hidden smDown>
        <DesktopAppBar menu={MENU} />
      </Hidden>
      <Hidden mdUp>
        <MobileAppBar menu={MENU} />
      </Hidden>
    </>
  )
}

export default Header

import { Link, LinkProps } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'

interface SkypeLinkProps extends LinkProps {
  showProtocolName?: boolean
}

const SkypeLink: FC<SkypeLinkProps> = ({
  showProtocolName = false,
  ...props
}) => {
  const {
    site: {
      siteMetadata: {
        contact: { skype },
      },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          contact {
            skype
          }
        }
      }
    }
  `)

  return (
    <Link color="textSecondary" href={`skype:${skype}?userinfo`} {...props}>
      {showProtocolName ? `skype:${skype}` : skype}
    </Link>
  )
}

export default SkypeLink

import { Container, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { FC } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      borderColor: theme.palette.primary.main,
      borderStyle: 'solid',
      borderWidth: '0px 0px 0px 3px',
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
  }),
)

interface FooterInfoItemProps {
  title: string
  content: React.ReactElement
}

const FooterInfoItem: FC<FooterInfoItemProps> = ({ title, content }) => {
  const classes = useStyles()

  return (
    <Container>
      <Typography className={classes.title} component="h3" variant="subtitle1">
        {title}
      </Typography>
      {content}
    </Container>
  )
}

export default FooterInfoItem

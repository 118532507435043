import { Link, LinkProps } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'

type PhoneLinkProps = LinkProps

const PhoneLink: FC<PhoneLinkProps> = (props) => {
  const {
    site: {
      siteMetadata: {
        contact: { phone },
      },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          contact {
            phone
          }
        }
      }
    }
  `)

  return (
    <Link color="textSecondary" href={`tel:${phone}`} {...props}>
      {phone}
    </Link>
  )
}

export default PhoneLink

import Container from '@material-ui/core/Container'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { FC } from 'react'

import SectionHeader from './SectionHeader'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(6),
    },
  }),
)

interface SectionLayoutProps {
  mainTitle?: string
  subTitle?: string
}

const SectionLayout: FC<SectionLayoutProps> = ({
  mainTitle,
  subTitle,
  children,
}) => {
  const classes = useStyles()

  return (
    <Container className={classes.container} fixed>
      {mainTitle && <SectionHeader mainTitle={mainTitle} subTitle={subTitle} />}
      {children}
    </Container>
  )
}

export default SectionLayout

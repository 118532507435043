import AppBar from '@material-ui/core/AppBar'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import React, { FC } from 'react'

import Logo from '../logo/Logo'
import { MenuItems } from '../Menu'
import DesktopMenu from './DesktopMenu'

const useStyles = makeStyles(() =>
  createStyles({
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
)

interface DesktopAppBarProps {
  menu: MenuItems
}

const DesktopAppBar: FC<DesktopAppBarProps> = ({ menu }) => {
  const classes = useStyles()

  return (
    <AppBar color="inherit" position="sticky">
      <Toolbar className={classes.toolbar}>
        <Logo />
        <DesktopMenu data={menu} />
      </Toolbar>
    </AppBar>
  )
}

export default DesktopAppBar

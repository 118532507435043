import { Box } from '@material-ui/core'
import React, { FC } from 'react'

import DropdownButton from '../../shared/DropdownButton'
import DropdownButtonItem from '../../shared/DropdownButtonItem'
import LinkButton from '../../shared/LinkButton'
import { MenuItems } from '../Menu'

type MenuItem = {
  link: string
  title: string
  submenu?: MenuItems
}
type SubmenuComponentProps = Required<MenuItem>

const SubmenuComponent: FC<SubmenuComponentProps> = ({
  link,
  title,
  submenu,
}) => {
  return (
    <DropdownButton title={title} to={link}>
      {submenu.map((item) => {
        return (
          <DropdownButtonItem
            key={item.title}
            title={item.title}
            to={item.link}
          />
        )
      })}
    </DropdownButton>
  )
}

interface DesktopMenuProps {
  data: MenuItems
}

const DesktopMenu: FC<DesktopMenuProps> = ({ data }) => {
  return (
    <Box display="flex" justifyContent="flex-end">
      {data.map((item) => {
        return item.submenu ? (
          <SubmenuComponent
            key={item.title}
            link={item.link}
            submenu={item.submenu}
            title={item.title}
          />
        ) : (
          <LinkButton key={item.title} title={item.title} to={item.link} />
        )
      })}
    </Box>
  )
}

export default DesktopMenu

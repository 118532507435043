import { Box, IconButton } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import React, { FC, useState } from 'react'

import { MenuItems } from '../Menu'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerHeader: {
      alignItems: 'center',
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
    },
    icon: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    link: {
      color: theme.palette.text.primary,
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      textDecoration: 'none',
      width: '100%',
    },
    linkNested: {
      color: theme.palette.text.primary,
      textDecoration: 'none',
      width: '100%',
    },
    listItem: {
      padding: 0,
    },
    listItemNested: {
      paddingLeft: theme.spacing(4),
    },
    root: {
      backgroundColor: theme.palette.background.paper,
      width: 300,
    },
  }),
)

const MobileMenuListItem: FC<{
  text: string
  link: string
  submenu?: MenuItems
  onClick: () => void
}> = ({ text, link, submenu, onClick }) => {
  const classes = useStyles()

  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <ListItem button className={classes.listItem} divider>
        <Box display="flex" flexGrow={1} onClick={onClick}>
          <AnchorLink className={classes.link} to={link}>
            <ListItemText primary={text} />
          </AnchorLink>
        </Box>
        {submenu && (
          <IconButton
            aria-label="menu"
            className={classes.icon}
            disableRipple
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            <ExpandMore />
          </IconButton>
        )}
      </ListItem>

      {submenu && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <MobileMenuNestedList items={submenu} />
        </Collapse>
      )}
    </>
  )
}

const MobileMenuNestedList: FC<{ items: MenuItems }> = ({ items }) => {
  const classes = useStyles()

  return (
    <List component="div" disablePadding>
      {items.map(({ title, link }) => (
        <Link className={classes.linkNested} key={title} to={link}>
          <ListItem button className={classes.listItemNested} key={title}>
            <ListItemText primary={title} />
          </ListItem>
        </Link>
      ))}
    </List>
  )
}

interface MobileMenuListProps {
  items: MenuItems
  onClose: () => void
}

const MobileMenuList: FC<MobileMenuListProps> = ({ items, onClose }) => {
  const classes = useStyles()

  return (
    <List
      aria-labelledby="nested-list-subheader"
      className={classes.root}
      component="nav"
    >
      <ListItem className={classes.listItem} divider>
        <div className={classes.drawerHeader}>
          <IconButton onClick={onClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
      </ListItem>
      {items.map(({ link, title, submenu }) => (
        <MobileMenuListItem
          key={title}
          link={link}
          onClick={onClose}
          submenu={submenu}
          text={title}
        />
      ))}
    </List>
  )
}

export default MobileMenuList

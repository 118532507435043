import Container from '@material-ui/core/Container'
import React, { FC } from 'react'

import FooterCopyright from './FooterCopyright'
import FooterInfo from './FooterInfo'

const Footer: FC = () => {
  return (
    <Container component="footer" disableGutters maxWidth={false}>
      <FooterInfo />
      <FooterCopyright />
    </Container>
  )
}

export default Footer

import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Link } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'

interface SocialMediaProps {
  iconClasses?: string
}

const SocialMedia: FC<SocialMediaProps> = ({ iconClasses }) => {
  const {
    site: {
      siteMetadata: {
        socialMedia: { facebookUrl },
      },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          socialMedia {
            facebookUrl
          }
        }
      }
    }
  `)

  return (
    <Box alignItems="center" display="flex">
      <Link
        aria-label={'facebookLink'}
        href={facebookUrl}
        rel="noopener"
        target="_blank"
      >
        <FontAwesomeIcon
          className={iconClasses}
          icon={faFacebookSquare}
          size="lg"
        />
      </Link>
    </Box>
  )
}

export default SocialMedia

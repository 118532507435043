import { Grid } from '@material-ui/core'
import React, { FC } from 'react'

import EmailLink from '../shared/EmailLink'
import PhoneLink from '../shared/PhoneLink'
import SkypeLink from '../shared/SkypeLink'

const Contact: FC = () => {
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <PhoneLink />
      </Grid>
      <Grid item>
        <EmailLink />
      </Grid>
      <Grid item>
        <SkypeLink showProtocolName />
      </Grid>
    </Grid>
  )
}

export default Contact

import { IconButton, SwipeableDrawer } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import React, { FC, useState } from 'react'

import Logo from '../logo/Logo'
import { MenuItems } from '../Menu'
import MobileMenuList from './MobileMenuList'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const iOS = process.browser && /iPad|iPhone|iPod/u.test(navigator.userAgent)

const useStyles = makeStyles(() =>
  createStyles({
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
)

interface MobileAppBarAppBarProps {
  menu: MenuItems
}

const MobileAppBar: FC<MobileAppBarAppBarProps> = ({ menu }) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <AppBar color="inherit" position="sticky">
      <Toolbar className={classes.toolbar}>
        <Logo />
        <IconButton
          aria-label="menu"
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          {isOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </Toolbar>
      <SwipeableDrawer
        anchor="left"
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        open={isOpen}
      >
        <MobileMenuList items={menu} onClose={() => setIsOpen(false)} />
      </SwipeableDrawer>
    </AppBar>
  )
}

export default MobileAppBar

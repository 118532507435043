import { Grid } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { FC } from 'react'

import SocialMedia from '../shared/SocialMedia'
import Contact from './Contact'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.primary.contrastText,
    },
  }),
)

const TopHeaderDesktop: FC = () => {
  const classes = useStyles()

  return (
    <Grid alignItems="center" container justify="space-between">
      <Grid item>
        <Contact />
      </Grid>
      <Grid item>
        <SocialMedia iconClasses={classes.icon} />
      </Grid>
    </Grid>
  )
}

export default TopHeaderDesktop

import { Container, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      lineHeight: '1rem',
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    text: {
      color: theme.palette.primary.contrastText,
    },
  }),
)

const FooterCopyright: FC = () => {
  const classes = useStyles()
  const {
    site: {
      siteMetadata: { siteName },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteName
        }
      }
    }
  `)

  return (
    <Container className={classes.root} maxWidth={false}>
      <Typography className={classes.text} variant="overline">
        {`© Copyright ${new Date().getFullYear()} - ${siteName}`}
      </Typography>
    </Container>
  )
}

export default FooterCopyright

import { Fab, Fade } from '@material-ui/core'
import UpIcon from '@material-ui/icons/KeyboardArrowUp'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { AnchorLink } from 'gatsby-plugin-anchor-links/index'
import React, { FC, useState } from 'react'

interface ScrollTopButtonProps {
  to: string
}

const ScrollTopButton: FC<ScrollTopButtonProps> = ({ to }) => {
  const [show, setShow] = useState(false)

  useScrollPosition(
    ({ currPos }) => {
      const isShow = currPos.y < 0

      if (isShow !== show) {
        setShow(isShow)
      }
    },
    [show],
  )

  return (
    <AnchorLink to={to}>
      <Fade in={show}>
        <Fab
          aria-label="scrollToTop"
          size="small"
          style={{
            backgroundColor: 'rgba(0,0,0,0.4)',
            bottom: '30px',
            position: 'fixed',
            right: '20px',
          }}
        >
          <UpIcon htmlColor="white" />
        </Fab>
      </Fade>
    </AnchorLink>
  )
}

export default ScrollTopButton

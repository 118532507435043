import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Link } from 'gatsby'
import React, { FC } from 'react'

import ImageLogo from './ImageLogo'

const useStyles = makeStyles(() =>
  createStyles({
    linkLogo: {
      display: 'flex',
    },
  }),
)

const Logo: FC = () => {
  const classes = useStyles()

  return (
    <Link aria-label={'logoLink'} className={classes.linkLogo} to="/">
      <ImageLogo />
    </Link>
  )
}

export default Logo

import { graphql, useStaticQuery } from 'gatsby'
import keywordExtractor from 'keyword-extractor'
import PropTypes from 'prop-types'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet'

const Seo: FC<SeoProps> = ({ desc, imageSrc, title, pathname, isBlogPost }) => {
  const {
    logo1x1,
    logo4x3,
    logo16x9,
    site: {
      siteMetadata: {
        author,
        desc: mainDesc,
        siteName,
        title: mainTitle,
        siteUrl,
        contact: { phone, address },
        keywords,
      },
    },
  } = useStaticQuery(
    graphql`
      query {
        logo1x1: file(relativePath: { eq: "logo-1x1.png" }) {
          childImageSharp {
            fixed(width: 500, height: 500, quality: 80) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        logo4x3: file(relativePath: { eq: "logo-4x3.png" }) {
          childImageSharp {
            fixed(width: 500, height: 375, quality: 80) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        logo16x9: file(relativePath: { eq: "logo-16x9.png" }) {
          childImageSharp {
            fixed(width: 800, height: 450, quality: 80) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        site {
          siteMetadata {
            author
            desc
            siteName
            title
            siteUrl
            keywords
            contact {
              phone
              address {
                streetAddress
                addressLocality
                addressRegion
                postalCode
                addressCountry
              }
            }
          }
        }
      }
    `,
  )

  const pageTitle = title ? `${title} — ${mainTitle}` : mainTitle
  const description = desc || mainDesc
  const image = imageSrc ? `${siteUrl}${imageSrc}` : null

  return (
    <Helmet htmlAttributes={{ lang: 'pl' }}>
      <meta charSet="utf-8" />
      <meta
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        name="viewport"
      />
      <title>{pageTitle}</title>
      {pathname && <link href={`${siteUrl}${pathname}`} rel="canonical" />}
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@id': `${siteUrl}`,
          '@type': 'LocalBusiness',
          address: {
            '@type': 'PostalAddress',
            addressCountry: `${address.addressCountry}`,
            addressLocality: `${address.addressLocality}`,
            addressRegion: `${address.addressRegion}`,
            postalCode: `${address.postalCode}`,
            streetAddress: `${address.streetAddress}`,
          },
          geo: {
            '@type': 'GeoCoordinates',
            latitude: 49.610535,
            longitude: 19.965412,
          },
          image: [
            `${logo1x1.childImageSharp.fixed.src}`,
            `${logo4x3.childImageSharp.fixed.src}`,
            `${logo16x9.childImageSharp.fixed.src}`,
          ],
          name: `${siteName}`,
          priceRange: '$$',
          telephone: `${phone}`,
          url: `${siteUrl}`,
        })}
      </script>
      <meta content={description} name="description" />
      <meta content={description} property="og:description" />
      <meta content={pageTitle} property="og:title" />
      <meta content={isBlogPost ? `article` : `website`} property="og:type" />
      <meta content={siteUrl} property="og:url" />
      <meta content={siteName} property="og:site_name" />
      {image && <meta content={image} property="og:image" />}
      <meta content="summary" name="twitter:card" />
      <meta content={author} name="twitter:creator" />
      <meta content={pageTitle} name="twitter:title" />
      {image && <meta content={image} property="twitter:image" />}
      <meta content={description} name="twitter:description" />
      <meta content="index,follow" name="robots" />
      <meta
        content={keywordExtractor
          // eslint-disable-next-line camelcase
          .extract(keywords, { remove_duplicates: true })
          .join(',')}
        name="keywords"
      />
    </Helmet>
  )
}

Seo.defaultProps = {
  desc: undefined,
  imageSrc: undefined,
  isBlogPost: false,
  pathname: undefined,
  title: undefined,
}

Seo.propTypes = {
  desc: PropTypes.string,
  imageSrc: PropTypes.string,
  isBlogPost: PropTypes.bool,
  pathname: PropTypes.string,
  title: PropTypes.string,
}

export interface SeoProps {
  desc?: string
  imageSrc?: string
  isBlogPost?: boolean
  pathname?: string
  title?: string
}

export default Seo

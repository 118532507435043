import React, { FC } from 'react'
import RehypeReact from 'rehype-react'

interface ComponentsMapping {
  [tagName: string]: React.ReactNode
}

const rehypeReactFactory = (componentsMapping: ComponentsMapping = {}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return new RehypeReact({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    components: componentsMapping,
    createElement: React.createElement,
    Fragment: React.Fragment,
  }).Compiler
}

interface HtmlAstComponentProps {
  componentsMapping?: ComponentsMapping
  htmlAst: unknown
}

const HtmlAstComponent: FC<HtmlAstComponentProps> = ({
  componentsMapping,
  htmlAst,
}) => {
  const renderAst = rehypeReactFactory(componentsMapping)

  return <>{renderAst(htmlAst)}</>
}

export default HtmlAstComponent

import { Box } from '@material-ui/core'
import React, { FC } from 'react'

const TextContainer: FC = ({ children }) => {
  return (
    <Box textAlign="justify">
      <>{children}</>
    </Box>
  )
}

export default TextContainer

import { Button, ButtonProps } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textTransform: 'none',
    },
    root: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      textDecoration: 'none',
    },
  }),
)

interface LinkButtonProps {
  to: string
  title: string
  buttonProps?: Partial<ButtonProps>
}

type LinkButtonRef = HTMLButtonElement

// eslint-disable-next-line react/display-name
const LinkButton = React.forwardRef<LinkButtonRef, LinkButtonProps>(
  ({ to, title, buttonProps }: LinkButtonProps, ref) => {
    const classes = useStyles()

    return (
      <AnchorLink className={classes.root} to={to}>
        <Button
          className={classes.button}
          ref={ref}
          size="large"
          {...buttonProps}
        >
          {title}
        </Button>
      </AnchorLink>
    )
  },
)

export default LinkButton
